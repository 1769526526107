<template>
  <el-dialog :title="title" :visible.sync="isShow" @closed="closed" :width="width">
    <template v-if="info">
      <div v-if="info[options.pk ? options.pk : 'id']">ID：{{info[options.pk ? options.pk : 'id']}}</div>
      <el-descriptions class="margin-top" :title="item.name" :column="options.cols" border v-for="(item,index) in params">
        <el-descriptions-item :label="subitem.name" v-for="(subitem,subindex) in item.tem">
          <template v-if="info[subitem.value] || parseInt(info[subitem.value]) === 0">
            <template v-if="subitem.type === 'image'">
              <el-image style="height: 60px;vertical-align: middle;" :src="info[subitem.value].indexOf('http')>= 0 ? info[subitem.value] : $config.assetsUrl + info[subitem.value]" :preview-src-list="info[subitem.value].indexOf('http')>= 0 ? [info[subitem.value]] : [$config.assetsUrl + info[subitem.value]]" v-if="info[subitem.value]"></el-image>
            </template>
            <template v-else-if="subitem.type === 'base64image'">
              <el-image style="height: 60px;vertical-align: middle;" :src="'data:image/jpeg;base64,'+info[subitem.value]" :preview-src-list="['data:image/jpeg;base64,'+info[subitem.value]]" v-if="info[subitem.value]"></el-image>
            </template>
            <template v-else-if="subitem.type === 'bool'">
              <el-tag :type="parseInt(info[subitem.value]) === 1 ? 'success' : 'danger'" disable-transitions>{{parseInt(info[subitem.value]) === 0 ? '否' : '是'}}</el-tag>
            </template>
            <template v-else-if="subitem.type === 'ywlx'">
              {{$utils.ywlx(info[subitem.value])}}
            </template>
            <template v-else-if="subitem.type === 'sex'">
              <span v-if="info[subitem.value] === '1'">男</span>
              <span v-if="info[subitem.value] === '2'">女</span>
            </template>
            <template v-else>
              {{info[subitem.value]}}
            </template>
          </template>
          <template v-else>-</template>
        </el-descriptions-item>
      </el-descriptions>
    </template>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="closed">关 闭</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "detail",
  data() {
      return {
        isShow:false,
        info:null,
        url:'',
        config:null,
        params:null,
        options:null,
      };
  },
  model:{
    prop:'showDialog',
    event:'change'
  },
  props: {
    showDialog: {
      type: Boolean,
      default: false
    },
    title:{
      type: String,
      default:'详情'
    },
    apiUrl:{
      type: String,
      default:''
    },
    id:{
      type: [String,Number],
      default:''
    },
    pk:{
      type: String,
      default:'id'
    },
    method:{
      type: String,
      default:'post'
    },
    width:{
      type: String,
      default:'900px'
    },
  },
  created() {
    let _config = this.$baseConfig

    this.config = _config[this.$route.path];

    this.params = this.config.detail.params ? this.config.detail.params : this.config.add.params;

    this.options = this.config.detail.options ? this.config.detail.options : this.config.add.options;
  },
  mounted() {
      if(this.id) {
          this.getDetail();
      }
  },
  methods: {
    closed(){
      this.isShow = false;

      this.$emit('change',this.isShow);
    },
    getDetail(){
      let _this = this;
      if(this.apiUrl && this.id && this.pk) {
        this.$http.request({
          method:this.method,
          url: this.apiUrl,
          datas: {[this.pk]:this.id},
          success(res) {
            _this.info = res;
          }
        });
      }
    },
  },
  watch:{
    showDialog(n,o){
      this.isShow = n;

      if(n) {
        this.getDetail();
      }else{
        this.info = null;
      }
    },
    info(n,o){
      this.datas = n;
    },
    id(n,o){
      this.getDetail();
    }
  }
};
</script>
